.block {
  max-width: 250px;
  text-align: center;
  margin: 20px;
  font-family: DMMono
}

@media (max-width: 820px), (max-height: 600px) {
  .block {
    margin: 0px;
  }
}

.nameText {
  font-weight: bold;
  color: #3CB3EB;
  margin-bottom: 12px;
  text-transform: uppercase;
}

.description {
  line-height: 140%;
}

@font-face {
  font-family: "newsreader-regular";   /*Can be any text*/
  src: local("Newsreader-regular"),
    url("./fonts/Newsreader_9pt-Regular.ttf") format("truetype");
}
.newsreader-regular {
  font-family: newsreader-regular;
}

@font-face {
  font-family: "DMMono";
  src: local("DMMono"),
    url("./fonts/DMMono-Regular.ttf") format("truetype");
}
.DMMono {
  font-family: DMMono;
}