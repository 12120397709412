@font-face {
  font-family: "DMMono"; /*Can be any text*/
  src: local("DMMono"), url("./fonts/DMMono-Regular.ttf") format("truetype");
}
.DMMono {
  font-family: DMMono;
}

.residencyPage {
  background-color: #202020;
  color: white;
  font-family: "DMMono";
}

.residencyContainer {
  padding: 100px;
  @media (max-width: 768px) {
    padding: 50px;
  }
  max-width: 950px;
  margin: 0 auto;
}

.residencySection {
  margin-bottom: 70px;
}

.residencyV2Logo {
  width: 80px;
  margin-bottom: 20px;
}

h1 {
  font-family: "PP Editorial New";
  font-weight: 400;
  font-size: 48px;
  line-height: 1.4;
  margin-bottom: 50px;

  @media (max-width: 768px) {
    line-height: 1.3;
  }
}

h2 {
  font-family: "PP Editorial New";
  font-weight: 400;
  font-size: 22px;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: 32px;
}

p {
  font-size: 18px;
  line-height: 1.5;
  margin-bottom: 28px;
}

li:not(:last-child) {
  margin-bottom: 8px;
}
