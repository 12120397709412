.gridBg {
  height: 100vh;
  width: 100vw;
  position: fixed;
  background-image: url('images/grid.png');
  background-position: center;
  background-repeat: repeat;
  background-size: cover;
  z-index: -1;
}

@media (max-width: 820px) {
  .heroImage {
    opacity: 40%;
  }
  .storyImage {
    opacity: 40%;
  }
}

.heroPage {
  height: 100vh;
  width: 100vw;
}

.heroImage {
  height: 100%;
  width: 100%;
  position: absolute;
  background-image: url('images/heroBackground.png');  
  background-position: right;           
  background-repeat: no-repeat;            
  background-size:cover;    
  z-index: -2;
}

.storyPage {
  height: 100vh;
}

.storyImage {
  height: 100%;
  width: 100%;
  position: absolute;
  background-image: url('images/storyBackground.png'); 
  background-position: right;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -2;
}