.peopleHeaderBlock {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.peopleHeaderText {
  font-size: 50px;
  text-align: center;
  margin-bottom: 16px;
  padding-top: 75px;
  padding-left: 10%;
  padding-right: 10%;
  line-height: 140%;
  font-family: PPEditorial;
  max-width: 600px;
  transition: font-size 1.0s ease;
}

.peopleSubHeaderText {
  font-size: 20px;
  text-align: center;
  max-width: 80ch;
  margin-bottom: 60px;
  margin-top: 30px;
  padding-left: 6%;
  padding-right: 6%;
  line-height: 140%;
  font-family: DMMono;
}

@media (max-width: 820px), (max-height: 600px) {
  .peopleHeaderText {
    font-size: 40px;
  }
  .peopleSubHeaderText {
    font-size: 18px;
  }
}

.italicizedHeaderText {
  font-style: italic;
  justify-content: center;
  align-items: center;
  color: #3CB3EB;
}

.people-flex-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  padding-left: 50px;
  padding-right: 50px;
  min-height: 100vh;
  gap: 16px;
}

.flex-item {
  /* flex: 1 1 250px; */
  max-width: 300px;
}

@media (max-width: 820px), (max-height: 600px) {
  .people-flex-container {
    padding-left: 0px;
    padding-right: 0px;
    gap: 50px;
  }
}

.orgImage {
  max-width: 120px;
  max-height: 50px;
}

.orgText {
  margin-top: 80px;
  margin-bottom: 20px;
  text-align: center;
  font-size: 24px;
  font-family: PPEditorial;
}

@media (max-width: 820px), (max-height: 600px) {
  .orgText {
    font-size: 20px;
  }
}

.footerDiv {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 80px;
  margin-bottom: 40px;
}

.footerImage {
  max-width: 100vw;
  height: auto;
}

@font-face {
  font-family: "newsreader-regular";
  src: local("Newsreader-regular"),
    url("./fonts/Newsreader_9pt-Regular.ttf") format("truetype");
}
.newsreader-regular {
  font-family: newsreader-regular;
}

@font-face {
  font-family: "DMMono";
  src: local("DMMono"),
    url("./fonts/DMMono-Regular.ttf") format("truetype");
}
.DMMono {
  font-family: DMMono;
}

@font-face {
  font-family: "PPEditorial";
  src: local("PPEditorial"),
    url("./fonts/PPEditorialNew-Regular-BF644b214ff145f.otf") format("truetype");
}
.PPEditorial {
  font-family: PPEditorial;
}