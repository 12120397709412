.storyTextContent {
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.storyHeaderText {
  font-size: 50px;
  text-align: center;
  margin-bottom: 16px;
  padding-top: 110px;
  line-height: 140%;
  font-family: PPEditorial;
  padding-left: 20px;
  padding-right: 20px;
  transition: font-size 1.0s ease;
}

.storySubHeaderText {
  font-size: 22px;
  text-align: center;
  margin-bottom: 72px;
  margin-top: 30px;
  line-height: 140%;
  max-width: 500px;
  font-family: DMMono;
  transition: font-size 1.0s ease;
}

@media (max-width: 820px), (max-height: 600px) {
  .storyHeaderText {
    font-size: 40px;
  }
  .storySubHeaderText {
    font-size: 20px;
  }
}

@media (max-width: 580px) {
  .storySubHeaderText {
    font-size: 18px;
  }
}

.storyStylizedText {
  color: #3CB3EB;
  font-weight: bold;
}

@font-face {
  font-family: "newsreader-regular"; 
  src: local("Newsreader-regular"),
    url("./fonts/Newsreader_9pt-Regular.ttf") format("truetype");
}
.newsreader-regular {
  font-family: newsreader-regular;
}

@font-face {
  font-family: "DMMono";
  src: local("DMMono"),
    url("./fonts/DMMono-Regular.ttf") format("truetype");
}
.DMMono {
  font-family: DMMono;
}

@font-face {
  font-family: "PPEditorial";
  src: local("PPEditorial"),
    url("./fonts/PPEditorialNew-Regular-BF644b214ff145f.otf") format("truetype");
}
.PPEditorial {
  font-family: PPEditorial;
}