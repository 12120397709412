.AppBar {
  display: flex;
  padding: 20px;
}

.logo {
  width: 45px;
  height: 30px;
  margin-right: 16px;
}

.stylizedToolbarHeaderText {
  color: #3cb3eb;
  font-style: italic;
}

.titleText {
  font-family: "PPEditorial";
  transition: font-size 1s ease;
  font-size: 20px;
  letter-spacing: 1px;
  margin-top: 5px;
}

@media (max-width: 580px) {
  .titleText {
    font-size: 16px;
  }
}

@font-face {
  font-family: "PPEditorial";
  src: local("PPEditorial"),
    url("./fonts/PPEditorialNew-Regular-BF644b214ff145f.otf") format("truetype");
}
.PPEditorial {
  font-family: PPEditorial;
}
