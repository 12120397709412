.titleBlock {
  margin-top: 10px;
  margin-left: 100px;
}

.heroHeader {
  font-family: PPEditorial;
  margin-top: 100px;
  max-width: 100vw;
  height: auto;
  font-size: 80px;
  transition: font-size 1.0s ease;
}

.heroSubHeader {
  font-size: 50px;
  font-family: PPEditorialUltralight;
  transition: font-size 1.0s ease;
}

@media (max-width: 820px), (max-height: 600px) {
  .heroHeader {
    font-size: 60px;
  }
  .heroSubHeader {
    font-size: 40px;
  }
}

@media (max-width: 580px) {
  .heroHeader {
    font-size: 54px;
    margin-top: 60px;
  }
  .heroSubHeader {
    font-size: 30px;
  }
  .titleBlock {
    margin-top: 10px;
    margin-left: 0px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
}

.heroDescriptiontext {
  margin-top: 60px;
  margin-bottom: 30px;
  font-family: DMMono;
  padding-right: 120px;
}

.stylizedHeroHeaderText {
  color: #3CB3EB;
  font-weight: bold;
}

@font-face {
  font-family: "newsreader-regular";   /*Can be any text*/
  src: local("Newsreader-regular"),
    url("./fonts/Newsreader_9pt-Regular.ttf") format("truetype");
}
.newsreader-regular {
  font-family: newsreader-regular;
}

@font-face {
  font-family: "DMMono";   /*Can be any text*/
  src: local("DMMono"),
    url("./fonts/DMMono-Regular.ttf") format("truetype");
}
.DMMono {
  font-family: DMMono;
}

@font-face {
  font-family: "PPEditorial";
  src: local("PPEditorial"),
    url("./fonts/PPEditorialNew-Regular-BF644b214ff145f.otf") format("truetype");
}
.PPEditorial {
  font-family: PPEditorial;
}

@font-face {
  font-family: "PPEditorialUltralight";
  src: local("PPEditorialUltralight"),
    url("./fonts/PPEditorialNew-UltralightItalic-BF644b214ff1e9b.otf") format("truetype");
}
.PPEditorialUltralight {
  font-family: PPEditorialUltralight;
}