.initiativesHeaderText {
  font-size: 50px;
  text-align: center;
  margin-bottom: 16px;
  margin-top: 20px;
  padding-top: 80px;
  line-height: 140%;
  font-family: PPEditorial;
  transition: font-size 1s ease;
}

.containerStyle {
  margin-top: 90px;
}

.footerText {
  font-size: 28px;
  text-align: center;
  margin-top: 50px;
  margin-left: 40px;
  margin-right: 40px;
  line-height: 180%;
  font-family: PPEditorial;
  transition: font-size 1s ease;
  margin-top: 100px;
  margin-bottom: 100px;
}

.initiativesStylizedText {
  font-style: italic;
}

.blockStyling {
  max-width: 375px;
  text-align: center;
}

.imgStyling {
  height: 375px;
}

@media (max-width: 820px), (max-height: 600px) {
  .initiativesHeaderText {
    font-size: 40px;
  }
  .footerText {
    font-size: 20px;
  }
  .containerStyle {
    margin-top: 40px;
  }
}

@media (max-width: 480px) {
  .initiativesHeaderText {
    font-size: 36px;
  }
  .blockStyling {
    max-width: 300px;
  }
  .imgStyling {
    height: 300px;
  }
}

.storyStylizedText {
  color: #3cb3eb;
}

.descriptionText {
  font-family: DMMono;
  font-size: 16px;
}

@font-face {
  font-family: "DMMono";
  src: local("DMMono"), url("./fonts/DMMono-Regular.ttf") format("truetype");
}
.DMMono {
  font-family: DMMono;
}

@font-face {
  font-family: "PPEditorial";
  src: local("PPEditorial"),
    url("./fonts/PPEditorialNew-Regular-BF644b214ff145f.otf") format("truetype");
}
.PPEditorial {
  font-family: PPEditorial;
}
